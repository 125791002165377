<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.764" x2="0.393" y2="1.057">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_corona" transform="translate(-821 -2337)">
    <rect :fill="background" data-name="Rectangle 185" height="183" id="Rectangle_185" transform="translate(821 2337)" width="183"/>
    <g data-name="MDI / virus" id="MDI_virus" style="isolation: isolate" transform="translate(859.096 2375.513)">
     <rect :fill="background" height="106.807" id="Boundary" opacity="0" width="106.807"/>
     <path d="M84.758,60.579a7.788,7.788,0,1,0,0-8.9H81.109a30.531,30.531,0,0,0-6.23-18.647l5.963-5.963a7.927,7.927,0,0,0,6.942-2.092,8.037,8.037,0,0,0,0-11.126,8.037,8.037,0,0,0-11.126,0,7.927,7.927,0,0,0-2.092,6.942L68.6,26.757a30.772,30.772,0,0,0-14.2-5.874v-6.23a7.788,7.788,0,1,0-8.9,0v6.23A30.9,30.9,0,0,0,34.381,24.71l-4.94-7.076a7.707,7.707,0,0,0-.89-7.12,7.784,7.784,0,1,0-6.408,12.238l5.251,7.477a27.714,27.714,0,0,0-4.717,6.408,7.835,7.835,0,0,0-8.322,1.691,8.037,8.037,0,0,0,0,11.126,8.995,8.995,0,0,0,4.45,2.225h0a32.218,32.218,0,0,0,.8,6.942l-5.83,1.558a7.785,7.785,0,1,0-2.937,13.484,7.734,7.734,0,0,0,5.251-4.9l6.72-1.825A33.655,33.655,0,0,0,30.6,76.155l-4.9,8.9a7.994,7.994,0,0,0-5.963,4.094,7.785,7.785,0,1,0,13.707,7.388,7.7,7.7,0,0,0,.089-7.165L38.3,80.606a31.606,31.606,0,0,0,11.66,2.225h.8a8.7,8.7,0,0,0-.8,3.916A7.81,7.81,0,1,0,62.64,80.116,32.275,32.275,0,0,0,68.6,76.6L79.017,87.014a7.927,7.927,0,0,0,2.092,6.942,8.037,8.037,0,0,0,11.126,0,7.8,7.8,0,0,0-6.942-13.217L74.879,70.325a30.992,30.992,0,0,0,4.94-9.746h4.94m-41.477-8.9A6.675,6.675,0,1,1,49.957,45a6.667,6.667,0,0,1-6.675,6.675M58.857,65.029a4.45,4.45,0,1,1,4.45-4.45A4.463,4.463,0,0,1,58.857,65.029Z" data-name="Path / virus" fill="url(#linear-gradient)" id="Path_virus" transform="translate(3.456 3.95)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>